import React, { useState } from 'react';

const PagesHeader = ({ title }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <div className='pages-header' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <h1 className='pages-title'>{title}</h1>
      {/* {title === 'About Midland' ? (
        <>
          <button
            id="download-forms-button"
            style={{ fontSize: '1.2rem', zIndex: '10', borderRadius: '5px', boxShadow: '0.5px 0.5px 5px yellow', margin: "0px -32px -106px" }}
            className='btn header-button-dark'
            onClick={togglePopup}
          >
            Download Forms
          </button>
          {isPopupOpen && (
            <div className="download-forms-popup">
                <a
                    className='btn header-button-yellow'
                    style={{ fontSize: '1.2rem', zIndex: '10', borderRadius: '5px', boxShadow: '0.5px 0.5px 2px yellow', alignSelf: 'center', minWidth: '380px', height: '50px' }}
                    href={"https://www.midlandortho.com/wp-content/uploads/2019/07/Patient-Registration-Forms-compressed.webp"}
                >
                    Download Patient Registration Form
                </a>
                <a
                    className='btn header-button-yellow'
                    style={{ fontSize: '1.2rem', zIndex: '10', borderRadius: '5px', boxShadow: '0.5px 0.5px 2px yellow', margin: '10px', minWidth: '380px', height: '50px' }}
                    href={"https://www.midlandortho.com/wp-content/uploads/2019/07/Workers-Compensation1_compressed.webp"}
                >
                    Download Worker's Compensation Form
                </a>
            </div>
          )}
        </>
      ) : null} */}
    </div>
  );
};

export default PagesHeader;
