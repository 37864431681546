import ExpertiseBlocks from '../../components/ExpertiseBlocks';

const Services = () => {
    return (
        <div style={{ maxWidth: "1600px" }} className='page-container'>
            <p className='expertise-header-description'>
                The Chicago orthopedic doctors and staff at Midland Orthopedic
                Associates provide a comprehensive level of services for our
                patients. Our physicians sub-specialize, having completed
                advanced specialty training within the field of Orthopedics in
                order to offer the highest level of care for even the most
                complicated of orthopedic conditions.
            </p>
            <ExpertiseBlocks />
        </div>
    );
};

export default Services;
