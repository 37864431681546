import { Link, useParams } from 'react-router-dom';
import './ColoredBlock.css';
import './SinglePhysician.css';

const ColoredBlock = ({ city, number, index }) => {
    const btnBackClass = index % 2 === 0 ? 'btn-back btn-back-1' : 'btn-back btn-back-2';

    return (
        <div className='colored-block'>
            <div class="block-container">
                <div className={btnBackClass}></div>

                <svg className="frame">
                    <rect rx="32" stroke="url(#gradient-half)" />
                </svg>
                <div className="btn-front">
                    <div className="text-city">{city}</div>
                    <div className="text-number">{number}</div>
                    <div className="text-icon">
                        <i style={{ marginRight: '5px' }} className='fas fa-mobile-alt fa-2x'></i>Call Us
                    </div>
                </div>
            </div>

            <svg style={{ visibility: "hidden", width: "0", height: "0"}}>
                <defs>
                    <linearGradient id="gradient-full" x1="0%" y1="0%" x2="120%" y2="120%">
                        <stop offset="0%" stop-color="#ffffff" />
                        <stop offset="100%" stop-color="#ffffff00" />
                    </linearGradient>
                    <linearGradient id="gradient-half" x1="-50%" y1="-50%" x2="100%" y2="100%">
                        <stop offset="0%" stop-color="#ffffff" />
                        <stop offset="100%" stop-color="#ffffff00" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};

export default ColoredBlock;
