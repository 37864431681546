import React, { useState, useEffect, Suspense } from 'react';
import ExpertiseBlocks from '../components/ExpertiseBlocks';
import { Link } from 'react-router-dom';
import ReactBackgroundCarousel from '../components/Carousel';
import CompanyReviewsPage from '../components/CompanyReviewsPage';
import TeamComponent from './lazyHome/TeamComponent';
import LocationComponent from './lazyHome/LocationComponent';
import MapContainer from '../components/googleMapReact/MapContainer';

const Home = () => {
    const [firstImageLoaded, setFirstImageLoaded] = useState(false);
    const [showMap, setShowMap] = useState(false);
    useEffect(() => {
        const firstImage = new Image();
        firstImage.src = 'https://i.imgur.com/ncDUiW4.webp';
        firstImage.onload = () => {
            setFirstImageLoaded(true);
        };
        return () => {
            firstImage.onload = null;
        };
    }, []);

    useEffect(() => {
        // Simulate loading delay of 3 seconds for the map
        const mapTimeout = setTimeout(() => {
            setShowMap(true);
        }, 1000);

        return () => clearTimeout(mapTimeout);
    }, []);

    const renderMapContainer = () => {
        if (showMap) {
            return <MapContainer />;
        }
        // Display a loading message during the delay
        return <p>Loading map...</p>;
    };
    return (
        <main className='main-content'>
            <div className='home-hero'>
                <div className='home-banner'>
                    <h1 className='banner-title'>
                        Welcome to Midland Orthopedic Associates
                    </h1>
                    <p className='banner-description'>
                        Since 1964 the physicians of Midland Orthopedic
                        Associates have provided our patients with the highest
                        quality orthopedic care. Our board certified and
                        fellowship trained physicians provide advanced,
                        comprehensive subspecialty orthopedic care in areas
                        including sports medicine, hand shoulder and upper
                        extremity, hip and knee, foot and ankle, total joint
                        replacement, arthroscopic surgery and trauma care and
                        have expertise in treating injured workers under
                        workers´ compensation.
                    </p>
                    <div className='banner-buttons'>
                        <div className='button-wrapper'>
                            <a
                                href='https://self.schdl.com/patient/13806'
                                className='btn header-button-yellow'>
                                Book Online
                            </a>
                        </div>
                        <div className='button-wrapper'>
                            <Link
                                className='btn header-button-blue'
                                to='/about'>
                                About Midland
                            </Link>
                        </div>
                    </div>
                </div>
                <ReactBackgroundCarousel>
                    <img
                        src='https://i.imgur.com/ncDUiW4.webp'
                        alt='img1'
                        className={`carousel-img ${firstImageLoaded ? 'loaded' : ''
                            }`}
                    />
                    <img
                        src='https://i.imgur.com/Uurig2v.webp'
                        alt='img2'
                        className='carousel-img'
                        loading='lazy'
                    />
                    <img
                        src='https://i.imgur.com/g45zeSd.webp'
                        alt='img3'
                        className='carousel-img'
                        loading='lazy'
                    />
                    <img
                        src='https://i.imgur.com/6NmjwHX.webp'
                        alt='img4'
                        className='carousel-img'
                        loading='lazy'
                    />
                    <img
                        src='https://i.imgur.com/aISSBI0.webp'
                        alt='img5'
                        className='carousel-img'
                        loading='lazy'
                    />
                </ReactBackgroundCarousel>
            </div>
            <div className='home-expertise'>
                <h2 className='section-title'>Areas of Expertise</h2>
                <ExpertiseBlocks />
            </div>
            <TeamComponent />
            <LocationComponent />
            <div className='home-map'>
                <div id="alert-div" className='location-icon-container'>
                    <h3 id="alert">Attention: As of November 1, 2023 Our La Grange Office has been relocated from Suite #340 to Suite #280</h3>
                </div>
                {renderMapContainer()}
            </div>
            <div className='home-reviews'>
                <h1 style={{ color: 'white', textShadow: '5px 3px black' }} className='section-title'>Company Reviews</h1>
                <CompanyReviewsPage />
            </div>
        </main>
    );
};
export default Home;
