import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const TeamComponent = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate a delay of 3 seconds (adjust as needed)
        const delay = 1000;
        const timeoutId = setTimeout(() => {
            setIsLoading(false);
        }, delay);

        // Cleanup the timeout on component unmount
        return () => clearTimeout(timeoutId);
    }, []);

    if (isLoading) {
        // Show a loading state while waiting for the delay to complete
        return <div>Loading...</div>;
    }

    return (
        <div className='home-team'>
            <div className='section-content'>
                <h2 className='section-title'>Meet Our Team</h2>
                <p className='section-description'>
                    The physicians at Midland Orthopedic Associates apply
                    the latest state of the art techniques in order to
                    return our patients to their active lifestyle. The
                    physicians of Midland Orthopedic Associates take pride
                    in providing value for our patients and emphasize a
                    patient-centered approach including access and
                    coordinated care which has resulted in our physicians
                    having among the highest patient satisfaction rates in
                    the nation.
                </p>

                <div className='text-center'>
                    <Link className='btn team-btn' to='/physicians'>
                        Our Team
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default TeamComponent;
