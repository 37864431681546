import React, { useState, useRef, useEffect } from 'react';
import PagesHeader from '../components/PagesHeader';
import { Link } from 'react-router-dom';
import MapContainer from '../components/googleMapReact/MapContainer';
import ChatBox from './../components/helpers/ChatBox';

const Locations = () => {
    const [isPopupOpen, setPopupOpen] = useState(false);
    const chatBoxRef = useRef(null);
    const locations = [
        {
          Name: 'Wabash',
          Phone: '312-420-8815',
          Fax: '312-949-3072',
          image: 'https://i.imgur.com/f0GMhdX.webp'
        },
        {
          Name: 'Greenwood',
          Phone: '312-724-6919',
          Fax: '773-375-4624',
          image: 'https://i.imgur.com/5hn0I26.webp'
        },
        {
          Name: 'Hometown',
          Phone: '312-736-2662',
          Fax: '708-425-2292',
          image: 'https://i.imgur.com/UCnvgKH.webp'
        },
    ];
    const togglePopup = () => {
        setPopupOpen(!isPopupOpen);
    };
    
    useEffect(() => {
        if (window.location.hash === '#chatbox') {
            setTimeout(() => {
                chatBoxRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 500);
        }
    }, []);

    return (
        <>
            <PagesHeader title='Locations' />;
            <div className='page-container'>
                <div className='page-info'>
                    <h3>
                        All locations have parking and parking for individuals
                        with disabilities available.
                    </h3>
                    <p className='page-description'>
                        Midland Orthopedic Associates offers same-day
                        appointments whenever possible. These may be scheduled
                        by phoning one of our four convenient locations. If you
                        do not require a same day appointment, you may schedule
                        either by phone or online.
                    </p>
                    <p className='page-link'>
                        <a
                        href='#'
                        className='page-link'
                        onClick={togglePopup}
                    >
                        Click here for Physical Therapy Hours and Locations{' '}
                        <i className='fas fa-long-arrow-alt-right'></i>
                        </a>
                    </p>
                </div>
            </div>
            {isPopupOpen && (
                <div className='popup-content-physical-therapy'>
                    {locations.map((place, index) => (
                    <div key={index} className='map-float-menu-info-physical-therapy'>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <h2 className='map-float-title-physical-therapy'>{place.Name}</h2>
                            <img
                                id='physical-therapy-office-image'
                                style={{ width: '100px', marginBottom: '-40px' }}
                                src={place.image}
                                alt={place.city}
                                className='info-window-image'
                            />
                        </div>
                        {place.Address && (
                        <div className='map-float-info-physical-therapy'>{place.Address}</div>
                        )}
                        {place.Address2 && (
                        <div className='map-float-info-physical-therapy'>{place.Address2}</div>
                        )}
                        <a
                            className='info-window-icon'
                            href={`tel:+1${place.Phone.split('-').join('')}`}
                            title='Phone clickable'>
                                {place.Phone}
                        </a>
                        <div style={{ fontSize: '1rem' }} className='map-float-info-physical-therapy'>Fax: {place.Fax}</div>
                            <p style={{ fontSize: '0.8rem', fontWeight: '700' }} className='map-float-title-physical-therapy'>
                                Physical Therapy
                            </p>
                            <div className='map-float-info-physical-therapy'>
                                Monday-Friday: 8 AM-5PM
                            </div>
                    </div>
                    ))}
                </div>
                )}
            <div id="alert-div" className='location-icon-container'>
                <h3 id="alert">Attention: As of November 1, 2023 Our La Grange Office has been relocated from Suite #340 to Suite #280</h3>
            </div>
            <div className='location-map-section'>
                <MapContainer />
            </div>
            <div ref={chatBoxRef} style={{ display: 'flex', justifyContent: 'center', padding: "110px 0px 45px 0px" }}>
                <ChatBox />
            </div>
        </>
    );
};

export default Locations;
